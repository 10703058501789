import request from '../Axios'

// 列表
export const goodsCategoryTree = () => {
  return request({
    url: '/goodsCategory/tree',
    method: 'GET'
  })
}

// 分类新增
export const goodsCategoryAdd = data => {
  return request({
    url: '/goodsCategory/add',
    method: 'post',
    data
  })
}

// 分类修改
export const goodsCategoryEdit = data => {
  return request({
    url: '/goodsCategory/edit',
    method: 'put',
    data
  })
}

// 分类删除
export const goodsCategoryDel= params => {
  return request({
    url: `/goodsCategory/del/${params.id}`,
    method: 'delete'
  })
}

// 分类状态
export const goodsCategoryState = params => {
  return request({
    url: `/goodsCategory/change/${params.id}`,
    method: 'put'
  })
}
