<template>
  <div class="productlist">
    <el-card class="box-card">
      <el-alert
        title="温馨提示：1.用户可以根据商品分类搜索商品，请正确创建分类；2.点击分类名称前符号，显示该商品分类的下级分类。"
        type="warning"
        show-icon
        :closable="false"
      />
    </el-card>
    <div class="btns">
      <el-button type="primary" @click="handleAdd">添加分类</el-button>
      <el-button @click="handleShowOrHide">{{
        defaultExpandAll ? '收起' : '展开'
      }}</el-button>
    </div>
    <el-table
      class="tabled"
      v-if="menuTable"
      :data="tableData"
      :default-expand-all="defaultExpandAll"
      border
      style="width: 100%"
      row-key="id"
    >
      <el-table-column label="分类名称" prop="name" />
      <el-table-column label="分类图片-未选中" prop="url" align="center">
        <template #default="scoped">
          <img :src="scoped.row.unselectedUrl" alt="" />
        </template>
      </el-table-column>
      <el-table-column
        label="分类图片-选中后"
        prop="url"
        align="center"
        class="selectedUrl"
      >
        <template #default="scoped">
          <img :src="scoped.row.selectedUrl" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="是否显示" align="center">
        <template #default="scoped">
          {{ scoped.row.isShow ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="sort" align="center" />
      <el-table-column label="创建时间" prop="createTime" align="center" />
      <el-table-column label="操作" align="center">
        <template #default="scoped">
          <el-button type="primary" size="small" @click="editItem(scoped.row)">
            编辑
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="deleteItem(scoped.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="dialog"
      :title="dialogTitle"
      width="50%"
      class="product_dialog"
      :before-close="dialogClose"
    >
      <div class="writeoff_box">
        <span><i>*</i>分类名称：</span>
        <el-input
          type="text"
          v-model="form.name"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <!-- <div class="writeoff_box">
        <span>父级分类：</span>
        <el-radio-group v-model="ptype" class="ml-4" @change="ptypeChange">
          <el-radio label="no" size="large">无父级分类</el-radio>
          <el-radio label="has" size="large">有父级分类</el-radio>
        </el-radio-group>
      </div> -->
      <div class="writeoff_box">
        <span><i>*</i>分类图片-未选中：</span>
        <div class="upload upload_s">
          <fileUpload
            v-if="dialog"
            @getimglist="getimglist"
            ref="uploadFilesDom"
            widthSize="72"
            heightSize="72"
            :imgUrl="form.unselectedUrl"
          />
        </div>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>分类图片-选中后：</span>
        <div class="upload upload_s">
          <fileUpload
            v-if="dialog"
            @getimglist="getimglistactive"
            ref="uploadFilesDomactive"
            widthSize="72"
            heightSize="72"
            :imgUrl="form.selectedUrl"
          />
        </div>
      </div>
      <div class="writeoff_box" v-if="ptype == 'has'">
        <span></span>
        <el-cascader
          v-model="form.pid"
          :options="treeList"
          :props="parentProps"
          clearable
          filterable
        />
      </div>
      <div class="writeoff_box remark_box">
        <span>排序：</span>
        <el-input
          v-model="form.sort"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span>是否显示：</span>
        <el-switch v-model="form.isShow" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, nextTick } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  goodsCategoryTree,
  goodsCategoryAdd,
  goodsCategoryEdit,
  goodsCategoryDel
} from '@/api/productManagement/productClassify'
import fileUpload from '@/components/fileUpload1'
const initData = reactive({
  loading: false,
  tableData: [],
  dialog: false,
  dialogTitle: '',
  treeList: [],
  defaultExpandAll: false,
  menuTable: true,
  ptype: 'no',
  form: {
    id: '',
    name: '',
    unselectedUrl: '',
    selectedUrl: '',
    sort: '',
    isShow: true
  },
  parentProps: {
    multiple: false,
    checkStrictly: true,
    label: 'name',
    value: 'id',
    children: 'children',
    emitPath: false
  }
})
onMounted(() => {
  getDataList()
})

const ptypeChange = () => {
  form.value.pid = ''
}

// 图片未选中上传
const getimglist = val => {
  form.value.unselectedUrl = val
}

// 图片选中上传
const getimglistactive = val => {
  form.value.selectedUrl = val
}

// 展开收起
const handleShowOrHide = () => {
  defaultExpandAll.value = !defaultExpandAll.value
  menuTable.value = false
  nextTick(() => {
    menuTable.value = true
  })
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  goodsCategoryTree().then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      tableData.value = res.data
      treeList.value = res.data
    } else {
      ElMessage.error(res.msg)
      tableData.value = []
      treeList.value = []
    }
  })
}

// 弹窗关闭
const dialogClose = () => {
  form.value = {
    id: '',
    name: '',
    unselectedUrl: '',
    selectedUrl: '',
    sort: '',
    isShow: true
  }
  dialog.value = false
}

// 添加
const handleAdd = () => {
  form.value = {
    id: '',
    name: '',
    unselectedUrl: '',
    selectedUrl: '',
    sort: '',
    isShow: true
  }
  dialogTitle.value = '新增栏目'
  dialog.value = true
}

// 编辑
const editItem = row => {
  dialogTitle.value = '编辑栏目'
  form.value.id = row.id
  form.value.name = row.name
  form.value.sort = row.sort
  form.value.isShow = row.isShow
  form.value.unselectedUrl = row.unselectedUrl
  form.value.selectedUrl = row.selectedUrl
  dialog.value = true
}

const submit = () => {
  if (form.value.name == '') {
    ElMessage.warning('请输入分类名称')
    return false
  }
  if (form.value.unselectedUrl == '') {
    ElMessage.warning('请上传分类图片-未选中')
    return false
  }
  if (form.value.selectedUrl == '') {
    ElMessage.warning('请上传分类图片-选中后')
    return false
  }
  let data = {
    name: form.value.name,
    isShow: form.value.isShow,
    unselectedUrl: form.value.unselectedUrl,
    selectedUrl: form.value.selectedUrl
  }
  if (form.value.sort != '') {
    data.sort = form.value.sort
  }
  if (form.value.id == '') {
    goodsCategoryAdd(data).then(({ data: res }) => {
      if (res.code == 200) {
        dialog.value = false
        ElMessage.success(res.msg)
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = form.value.id
    goodsCategoryEdit(data).then(({ data: res }) => {
      if (res.code == 200) {
        dialog.value = false
        ElMessage.success(res.msg)
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 删除
const deleteItem = row => {
  ElMessageBox.confirm('确定要删除该商品吗？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      goodsCategoryDel({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

const {
  loading,
  tableData,
  dialog,
  dialogTitle,
  treeList,
  defaultExpandAll,
  menuTable,
  form,
  ptype,
  parentProps
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.productlist {
  .btns {
    margin: 20px 0;
  }

  .product_dialog {
    .writeoff_box {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      span {
        width: 140px;
        text-align: right;

        i {
          color: red;
        }
      }

      .dialog_input {
        width: 50%;
      }
    }

    .upload {
      width: 150px;
      height: 150px;
    }
  }
  :deep(.upload_s) {
    display: flex;
    width: 360px !important;
    height: auto !important;
    justify-content: space-between;
    .status {
      display: block;
      width: 100% !important;
      text-align: center !important;
      margin-bottom: 5px;
    }
    .uploadbox1 {
      width: 170px;
      box-sizing: border-box;
    }
    .el-upload-dragger {
      padding: 0;
      height: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
</style>
<style lang="scss">
.tabled tr td:nth-child(3) {
  background: #ccc !important;
}
</style>
